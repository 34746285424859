<template>
  <div class="spotEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ 'Spot' }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('type') }}</label>
        <select
          v-model="spot.type"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
        >
          <option
            :value="null"
            disabled
          >
            {{ $t('pleaseSelectOne') }}
          </option>
          <option
            v-for="(type, index) in spotTypes"
            :key="`spotType-spot-${ index }`"
            :value="type"
          >
            {{ `${ type }` }}
          </option>
        </select>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="spot.name"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('visible') }}</label>
        <input
          v-model="spot.visible"
          type="checkbox"
          class="ml-3"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!spot.type || !spot.name"
        style="background-color: #e6007e; border-color: #e6007e;"
        @click.prevent="editSpot()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />{{ $t('edit') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SpotEdit",
  mixins: [
    errorMixin
  ],
  props: {
    spot: {
      type: Object,
      required: true
    }
  },
  computed: {
    spotTypes () {
      return ['Carving', 'Cross', 'Downhill', 'Flight', 'Freeride', 'GiantSlalom', 'GlacierExperience', 'MixRace', 'Moguls', 'Photostart', 'Race', 'Slalom', 'Sledding', 'Speed', 'WinterHiking'];
    },
  },
  methods: {
    async editSpot () {
      await this.axios.post('/Resort/UpdateSpotForResort', this.spot)
        .then(() => {
          this.$snotify.success(this.$t('resortSpotAndPhotopoint.updatedSuccessfullySpot'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.spotEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>