var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spotList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.spots),"columns":_vm.kgm_responsiveColumns(),"filterable":false,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.spots),"sortable":{
        allowUnsort: true,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"skimovieName",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.skimovieSpot)?_c('span',[_vm._v(_vm._s("- " + (props.dataItem.skimovieSpot.name) + (props.dataItem.skimovieSpotToPublish ? '\n \r - ' + props.dataItem.skimovieSpotToPublish.name : '')))]):_vm._e()])]}},{key:"skimovieType",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.skimovieSpot)?_c('span',[_vm._v(_vm._s("" + (props.dataItem.skimovieSpot.spotType) + (props.dataItem.skimovieSpotToPublish ? ' - ' + props.dataItem.skimovieSpotToPublish.spotType : '')))]):_vm._e()])]}},{key:"skimoviePublish",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.skimovieSpot)?_c('span',[_vm._v(_vm._s("" + (props.dataItem.skimovieSpot.publishToSkiline) + (props.dataItem.skimovieSpotToPublish ? ' - ' + props.dataItem.skimovieSpotToPublish.publishToSkiline : '')))]):_vm._e()])]}},{key:"skimovieVisible",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.skimovieSpot)?_c('span',[_vm._v(_vm._s("" + (props.dataItem.skimovieSpot.active) + (props.dataItem.skimovieSpotToPublish ? ' - ' + props.dataItem.skimovieSpotToPublish.active : '')))]):_vm._e()])]}},{key:"booleanTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('input',{attrs:{"type":"checkbox","disabled":"","readonly":""},domProps:{"checked":_vm.kgm_getNestedValue(props.field, props.dataItem)}})])]}},{key:"optionsTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",staticStyle:{"background-color":"#e6007e","border-color":"#e6007e"},on:{"click":function($event){return _vm.showSidebarEdit(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}}),_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])],1),(props.dataItem.skimovieSpot)?_c('button',{staticClass:"btn btn-primary btn-sm ml-1",staticStyle:{"background-color":"#00a0e3"},on:{"click":function($event){return _vm.showSidebarSkimovieSpotEdit(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}}),_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])],1):_vm._e()])])]}}])}),(_vm.showSidebar)?_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":_vm.close}},[(_vm.skimovieSpotEdit)?_c('SkimovieSpotEdit',{attrs:{"skimovie-spot":_vm.spot.skimovieSpot,"publish-to-skimovie-spot":_vm.spot.skimovieSpotToPublish,"resort-id":_vm.resortId},on:{"reload":_vm.reloadSpots}}):(_vm.addingElement)?_c('SpotAdd',{attrs:{"resort-id":_vm.resortId},on:{"reload":_vm.reloadSpots}}):_c('SpotEdit',{attrs:{"resort-id":_vm.resortId,"spot":_vm.spot},on:{"reload":_vm.reloadSpots}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }