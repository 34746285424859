<template>
  <div class="spotAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ 'Spot' }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('type') }}</label>
        <select
          v-model="spot.type"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
        >
          <option
            :value="null"
            disabled
          >
            {{ $t('pleaseSelectOne') }}
          </option>
          <option
            v-for="(type, index) in spotTypes"
            :key="`spotType-spot-${ index }`"
            :value="type"
          >
            {{ `${ type }` }}
          </option>
        </select>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="spot.name"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('visible') }}</label>
        <input
          v-model="spot.visible"
          type="checkbox"
          class="ml-3"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!spot.type || !spot.name"
        @click.prevent="addSpot()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SpotAdd",
  mixins: [
    errorMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      spot: {}
    }
  },
 computed: {
    spotTypes () {
      return ['Carving', 'Cross', 'Downhill', 'Flight', 'Freeride', 'GiantSlalom', 'GlacierExperience', 'MixRace', 'Moguls', 'Photostart', 'Race', 'Slalom', 'Sledding', 'Speed', 'WinterHiking'];
    },
  },
  methods: {
    async addSpot () {
      let postObject = {
        'type': this.spot.type,
        'name': this.spot.name,
        'resortId': this.resortId,
        'visible': this.spot.visible
      }
      await this.axios.post('/Resort/CreateSpotForResort', postObject)
        .then(() => {
          this.$snotify.success(this.$t('resortSpotAndPhotopoint.addedSuccessfullySpot'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.spotAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>