<template>
  <div class="skimovieSpotEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ 'Skimovie Spot' }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ 'Id' }}</label>
        <input
          v-model="skimovieSpot.id"
          disabled
          :class="['form-control', { 'is-invalid': $validator.errors.has('InstallationId') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('type') }}</label>
        <select
          v-model="skimovieSpot.spotType"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
        >
          <option
            :value="null"
            disabled
          >
            {{ $t('pleaseSelectOne') }}
          </option>
          <option
            v-for="(type, index) in spotTypes"
            :key="`spotType-spot-${ index }`"
            :value="type"
          >
            {{ `${ type }` }}
          </option>
        </select>
        <hr class="m-0 mb-3">
        <label>{{ $t('name') }}</label>
        <input
          v-model="skimovieSpot.name"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ 'Installation Id' }}</label>
        <input
          v-model="skimovieSpot.installationId"
          :class="['form-control', { 'is-invalid': $validator.errors.has('InstallationId') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ 'Publish to Spot Id' }}</label>
        <input
          v-model="skimovieSpot.publishSpotId"
          :class="['form-control', { 'is-invalid': $validator.errors.has('PublishSpotId') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ 'Publish to Skiline' }}</label>
        <input
          v-model="skimovieSpot.publishToSkiline"
          type="checkbox"
          class="ml-3"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('visible') }}</label>
        <input
          v-model="skimovieSpot.active"
          type="checkbox"
          class="ml-3"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!skimovieSpot.spotType || !skimovieSpot.name"
        @click.prevent="editSpot(skimovieSpot, false)"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />{{ $t('edit') }}
      </button>
      <div class="clearfix" />
    </form>
    <template v-if="publishToSkimovieSpot">
      <form>
        <div class="form-group mt-4">
          <h4 class="m-0 mt-3">
            {{ 'Skimovie Publish to Spot' }}
          </h4>
          <hr class="m-0 mb-3">
          <label>{{ 'Id' }}</label>
          <input
            v-model="publishToSkimovieSpot.id"
            disabled
            :class="['form-control', { 'is-invalid': $validator.errors.has('InstallationId') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('type') }}</label>
          <select
            v-model="publishToSkimovieSpot.spotType"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
          >
            <option
              :value="null"
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, index) in spotTypes"
              :key="`spotType-spot-${ index }`"
              :value="type"
            >
              {{ `${ type }` }}
            </option>
          </select>
          <hr class="m-0 mb-3">
          <label>{{ $t('name') }}</label>
          <input
            v-model="publishToSkimovieSpot.name"
            :class="['form-control', { 'is-invalid': $validator.errors.has('Name') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ 'Installation Id' }}</label>
          <input
            v-model="publishToSkimovieSpot.installationId"
            :class="['form-control', { 'is-invalid': $validator.errors.has('InstallationId') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ 'Publish to Spot Id' }}</label>
          <input
            v-model="publishToSkimovieSpot.publishSpotId"
            :class="['form-control', { 'is-invalid': $validator.errors.has('PublishSpotId') }]"
            type="text"
          >
          <hr class="m-0 mb-3">
          <label>{{ 'Publish to Skiline' }}</label>
          <input
            v-model="publishToSkimovieSpot.publishToSkiline"
            type="checkbox"
            class="ml-3"
          >
          <hr class="m-0 mb-3">
          <label>{{ $t('visible') }}</label>
          <input
            v-model="publishToSkimovieSpot.active"
            type="checkbox"
            class="ml-3"
          >
        </div>
        <button
          class="btn btn-primary float-right"
          :disabled="!publishToSkimovieSpot.spotType || !publishToSkimovieSpot.name"
          @click.prevent="editSpot(publishToSkimovieSpot, true)"
        >
          <font-awesome-icon
            class="mr-2"
            icon="edit"
          />{{ $t('edit') }}
        </button>
        <div class="clearfix" />
      </form>
    </template>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "SkimovieSpotEdit",
  mixins: [
    errorMixin
  ],
  props: {
     resortId: {
      type: Number,
      required: true
    },
    skimovieSpot: {
      type: Object,
      required: true
    },
    publishToSkimovieSpot: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    spotTypes () {
      return ['Carving', 'Cross', 'Downhill', 'Flight', 'Freeride', 'GiantSlalom', 'GlacierExperience', 'MixRace', 'Moguls', 'Photostart', 'Race', 'Slalom', 'Sledding', 'Speed', 'WinterHiking'];
    },
  },
  methods: {
    async editSpot (spot, isToPublish) {
      await this.axios.post(`/Resort/UpdateSkimovieSpot?resortId=${ this.resortId }&isToPublish=${ isToPublish }`, spot)
        .then(() => {
          this.$snotify.success(this.$t('resortSpotAndPhotopoint.updatedSuccessfullySpot'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.skimovieSpotEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>