<template>
  <div class="spotList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(spots)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(spots)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="skimovieName"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span
              v-if="props.dataItem.skimovieSpot"
            >{{ `- ${ props.dataItem.skimovieSpot.name }` + (props.dataItem.skimovieSpotToPublish ? '\n \r - ' + props.dataItem.skimovieSpotToPublish.name : '') }}</span>
          </td>
        </template>
        <template
          slot="skimovieType"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span
              v-if="props.dataItem.skimovieSpot"
            >{{ `${ props.dataItem.skimovieSpot.spotType }` + (props.dataItem.skimovieSpotToPublish ? ' - ' + props.dataItem.skimovieSpotToPublish.spotType : '') }}</span>
          </td>
        </template>
        <template
          slot="skimoviePublish"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span
              v-if="props.dataItem.skimovieSpot"
            >{{ `${ props.dataItem.skimovieSpot.publishToSkiline }` + (props.dataItem.skimovieSpotToPublish ? ' - ' + props.dataItem.skimovieSpotToPublish.publishToSkiline : '') }}</span>
          </td>
        </template>
        <template
          slot="skimovieVisible"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span
              v-if="props.dataItem.skimovieSpot"
            >{{ `${ props.dataItem.skimovieSpot.active }` + (props.dataItem.skimovieSpotToPublish ? ' - ' + props.dataItem.skimovieSpotToPublish.active : '') }}</span>
          </td>
        </template>
        <template
          slot="booleanTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <input
              :checked="kgm_getNestedValue(props.field, props.dataItem)"
              type="checkbox"
              disabled
              readonly
            >
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                style="background-color: #e6007e; border-color: #e6007e"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                v-if="props.dataItem.skimovieSpot"
                class="btn btn-primary btn-sm ml-1"
                style="background-color: #00a0e3"
                @click="showSidebarSkimovieSpotEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <SkimovieSpotEdit
          v-if="skimovieSpotEdit"
          :skimovie-spot="spot.skimovieSpot"
          :publish-to-skimovie-spot="spot.skimovieSpotToPublish"
          :resort-id="resortId"
          @reload="reloadSpots"
        />
        <SpotAdd
          v-else-if="addingElement"
          :resort-id="resortId"
          @reload="reloadSpots"
        />
        <SpotEdit
          v-else
          :resort-id="resortId"
          :spot="spot"
          @reload="reloadSpots"
        />
      </Sidebar>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from "@/mixins/kendoGridMixin.js";
import SpotAdd from "@/components/Resort/SpotAdd";
import SpotEdit from "@/components/Resort/SpotEdit";
import SkimovieSpotEdit from "@/components/Resort/SkimovieSpotEdit";
import Sidebar from "@/components/Base/Sidebar.vue";

export default {
  name: "SpotList",
  components: {
    Sidebar,
    SpotAdd,
    SpotEdit,
    SkimovieSpotEdit
  },
  mixins: [kendoGridMixin],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      spots: null,
      spot: null,
      showSidebar: false,
      addingElement: false,
      skimovieSpotEdit: false,
      kgm_columns: [
        {
          filterable: false,
          title: "Skiline DB",
          children: [
            {
              field: "id",
              filterable: false,
              filter: "text",
              title: "Id",
              width: "75px"
            },
            {
              field: "name",
              filterable: false,
              title: "Name"
            },
            {
              field: "type",
              filterable: false,
              filter: "text",
              title: "Type",
              hideOn: ["smDown"],
              width: "120px"
            },
            {
              field: "externalId",
              filterable: false,
              title: "External Id",
              hideOn: ["smDown"],
              width: "95px"
            },
            {
              field: "visible",
              filterable: false,
              title: "Visible",
              cell: "booleanTemplate",
              hideOn: ["smDown"],
              width: "75px"
            }
          ]
        },
        {
          filterable: false,
          title: "Skimovie DB",
          children: [
            {
              title: "Name",
              cell: "skimovieName",
            },
            {
              title: "Type",
              cell: "skimovieType",
              hideOn: ["smDown"],
              width: "120px"
            },
            {
              title: "Visible",
              cell: "skimovieVisible",
              hideOn: ["smDown"],
              width: "130px"
            },
            {
              title: "Publish to Skiline",
              cell: "skimoviePublish",
              hideOn: ["smDown"],
              width: "140px"
            },
          ]
        },
        {
          filterable: false,
          children: [
            {
              filterable: false,
              title: "Options",
              cell: "optionsTemplate"
            }
          ]
        }
      ]
    };
  },
  watch: {
    resortId () {
      this.getData();
    }
  },
  created () {
    this.getData();
  },
  methods: {
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.skimovieSpotEdit = false;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentSpot(emitValues);
      this.addingElement = false;
      this.skimovieSpotEdit = false;
      this.show();
    },
    showSidebarSkimovieSpotEdit (emitValues) {
      this.setCurrentSpot(emitValues);
      this.addingElement = false;
      this.skimovieSpotEdit = true;
      this.show();
    },
    setCurrentSpot (spot) {
      this.spot = JSON.parse(JSON.stringify(spot));
    },
    reloadSpots () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getData();
    },
    //#region API-calls
    getData () {
      this.loading = true;
      this.axios
        .get(`/Resort/GetResortSpots?resortId=${this.resortId}`)
        .then(response => {
          this.spots = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.spotList .k-grid table tr:hover td {
  background: rgb(219, 219, 219) !important;
  cursor: pointer !important;
}

.spotList .k-grid table thead tr:first-child th:nth-child(2)  {
  background: #00a0e3 !important;
  text-align: center;
  color: white;
}

.spotList .k-grid table thead tr:first-child th:first-child  {
  background: #e6007e !important;
  text-align: center;
  color: white;
}
</style>